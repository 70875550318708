import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function App() {
  const [ user, setUser ] = useState(null);
  const [ profile, setProfile ] = useState(null);
  const [ phrase, setPhrase ] = useState(null);

  const getJWT = (googleCode) => {
        console.log(googleCode)
        //axios.post('http://localhost:5000/login', {
        axios.post('https://api.tikawi.thuault.com/login', {
            code: googleCode,
        }, { withCredentials: true }).then((res) => {
            console.log(res.data)
            setUser(res.data.tikawi)
        }).catch((error) => {
            console.error(error)
        })
    }

  const login = useGoogleLogin({
    onSuccess: (res) => getJWT(res.code),
    onError: (error) => console.log('Login Failed:', error),
    flow: 'auth-code',
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

    useEffect(
        () => {
            axios.get('https://api.tikawi.thuault.com', { withCredentials: true })
                .then((res) => {
                    setPhrase(res.data.message)
                    setUser(res.data.profile)
                    console.log(res.data)
                }).catch((error) => {
                console.error(error)
            })
        },
        []
    );

  useEffect(
      () => {
          if (user) {
              console.log(user)
              if (user === "tiki") {
                  setProfile("Tiki")
              } else if (user === "tawi") {
                  setProfile("Tawi")
              } else {
                  console.log("invalid user")
              }
          } else {
              console.log("no user")
          }
      },
      [ user ]
  );


  return (
      <div>
        <h2>Tikawi</h2>
        <br />
        {profile ? (
            <div>
              <p>Hello {profile}!</p>
              <br />
              { phrase }
            </div>
        ) : (
            <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </div>
  );
}
export default App;
